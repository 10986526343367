<template>
  <v-menu
    v-model="showNotifications"
    :close-on-content-click="false"
    offset-y
    :max-width="isMobile ? '100vw' : '400'"
    class="notification-menu"
    @click:outside="handleOutsideClick"
  >
    <template v-slot:activator="{ on, attrs }">
      <button 
        v-bind="attrs"
        v-on="on"
        class="notification-button cursor-pointer"
      >
        <v-badge v-if="unreadCount > 0" dot color="red" overlap>
          <img src="@/assets/img/bell.svg" class="cursor-pointer" />
        </v-badge>
        <img v-else src="@/assets/img/bell.svg" class="cursor-pointer" />
      </button>
    </template>

    <v-card class="notification-panel w-full sm:w-[400px]">
      <div class="notification-header">
        <div class="flex justify-center items-center px-3 sm:px-4 py-3 sm:py-4">
          <h3 class="text-base sm:text-lg font-medium">Notifications</h3>
        </div>
        
        <div class="tabs-container bg-gray-100">
          <div class="tab-buttons">
            <button 
              @click="activeTab = 'unread'"
              :class="['tab-btn text-sm sm:text-base', activeTab === 'unread' ? 'active' : '']"
            >
              <span class="whitespace-nowrap">Unread ({{ unreadCount }})</span>
            </button>
            <button 
              @click="activeTab = 'read'"
              :class="['tab-btn text-sm sm:text-base', activeTab === 'read' ? 'active' : '']"
            >
              <span class="whitespace-nowrap">Read</span>
            </button>
          </div>
        </div>
      </div>

      <div class="notifications-container">
        <div v-if="notifications.length > 0">
          <div
            v-for="(notification, index) in filteredNotifications"
            :key="`${notification.id}-${activeTab}-${index}`"
            class="notification-item p-3 sm:p-4 hover:bg-gray-50 cursor-pointer"
            @click="handleNotificationClick(notification)"
          >
            <div class="flex items-start gap-2 sm:gap-3">
              <div class="notification-icon">
                <img 
                  :src="getNotificationIcon(notification)"
                  class="w-5 h-5 sm:w-6 sm:h-6 rounded-full"
                  @error="handleImageError"
                  alt="notification"
                />
              </div>
              <div class="flex-1 min-w-0">
                <div class="flex justify-between items-start gap-2">
                  <h4 class="font-semibold text-xs sm:text-sm truncate">{{ notification.title }}</h4>
                  <span class="text-[10px] sm:text-xs text-gray-500 whitespace-nowrap">{{ formatDate(notification.date) }}</span>
                </div>
                
                <p class="text-xs sm:text-sm text-gray-600 mt-1" :class="{ 'truncated': !expandedMessages[notification.id] }">
                  {{ notification.message }}
                </p>
                
                <button 
                  v-if="notification.message.length > 100"
                  @click.stop="toggleMessage(notification.id)"
                  class="text-[10px] sm:text-xs text-[#8050DE] mt-1"
                >
                  {{ expandedMessages[notification.id] ? 'Show less' : 'Show more' }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <infinite-loading 
          @infinite="loadMore"
          :identifier="infiniteId"
          spinner="spiral"
        >
          <div slot="spinner">
            <v-skeleton-loader
              v-for="n in 3"
              :key="n"
              type="list-item-two-line"
            ></v-skeleton-loader>
          </div>
          <div slot="no-more">No more notifications</div>
          <div slot="no-results">No notifications found</div>
        </infinite-loading>
      </div>

      <div class="mark-all-button">
        <button 
          class="text-[#8050DE] text-xs sm:text-sm"
          @click="markAllAsRead"
          v-if="unreadCount > 0"
        >
          Mark all as read
        </button>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import { collection, query, orderBy, limit, startAfter, where, getDocs, onSnapshot, writeBatch } from 'firebase/firestore'
import { db, auth } from '@/utils/firebase.utils'
import moment from 'moment'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'NotificationPanel',
  
  components: {
    InfiniteLoading
  },
  
  data() {
    return {
      showNotifications: false,
      notifications: [],
      unreadNotificationsCount: 0,
      activeTab: 'unread',
      lastDoc: null,
      expandedMessages: {},
      infiniteId: +new Date(),
      batchSize: 20,
      unsubscribe: null,
      unreadCountUnsubscribe: null
    }
  },

  computed: {
    isMobile() {
      return window.innerWidth <= 350
    },
    unreadCount() {
      return this.unreadNotificationsCount
    },
    
    filteredNotifications() {
      return this.notifications.filter(n => 
        this.activeTab === 'unread' ? n.status === 'unread' : n.status === 'read'
      )
    }
  },

  mounted() {
    this.initializeNotifications()
  },

  beforeDestroy() {
    this.cleanup()
  },

  methods: {
    initializeNotifications() {
      this.setupRealtimeListener()
      this.setupUnreadCounter()
    },

    cleanup() {
      if (this.unsubscribe) this.unsubscribe()
      if (this.unreadCountUnsubscribe) this.unreadCountUnsubscribe()
    },

    setupUnreadCounter() {
      if (!auth.currentUser) return

      const unreadQuery = query(
        collection(db, 'users', auth.currentUser.uid, 'notifications'),
        where('status', '==', 'unread')
      )

      this.unreadCountUnsubscribe = onSnapshot(unreadQuery, (snapshot) => {
        this.unreadNotificationsCount = snapshot.docs.length
      })
    },

    setupRealtimeListener() {
      if (!auth.currentUser) return

      const status = this.activeTab === 'unread' ? 'unread' : 'read'
      
      const realtimeQuery = query(
        collection(db, 'users', auth.currentUser.uid, 'notifications'),
        where('status', '==', status),
        orderBy('date', 'desc'),
        limit(this.batchSize)
      )

      this.unsubscribe = onSnapshot(realtimeQuery, (snapshot) => {
        this.notifications = []
        
        snapshot.docChanges().forEach((change) => {
          const notification = {
            id: change.doc.id,
            ...change.doc.data()
          }
          
          if (change.type === 'added') {
            const index = this.notifications.findIndex(n => n.id === notification.id)
            if (index === -1) {
              this.notifications.push(notification)
            }
          } else if (change.type === 'modified') {
            const index = this.notifications.findIndex(n => n.id === notification.id)
            if (index !== -1) {
              this.$set(this.notifications, index, notification)
            }
          } else if (change.type === 'removed') {
            const index = this.notifications.findIndex(n => n.id === notification.id)
            if (index !== -1) {
              this.notifications.splice(index, 1)
            }
          }
        })

        this.notifications.sort((a, b) => b.date.seconds - a.date.seconds)
      })
    },

    async loadMore($state) {
      try {
        if (!auth.currentUser) {
          $state.complete()
          return
        }

        const status = this.activeTab === 'unread' ? 'unread' : 'read'
        const queryConstraints = [
          where('status', '==', status),
          orderBy('date', 'desc'),
          limit(this.batchSize)
        ]
        
        if (this.lastDoc) {
          queryConstraints.push(startAfter(this.lastDoc))
        }

        const snapshot = await getDocs(
          query(collection(db, 'users', auth.currentUser.uid, 'notifications'), ...queryConstraints)
        )

        if (snapshot.empty) {
          $state.complete()
          return
        }

        this.lastDoc = snapshot.docs[snapshot.docs.length - 1]
        const newNotifications = snapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(newNote => !this.notifications.some(existingNote => existingNote.id === newNote.id))

        this.notifications.push(...newNotifications)
        $state.loaded()
      } catch (error) {
        console.error('Error loading notifications:', error)
        $state.error()
      }
    },

    async markAllAsRead() {
      try {
        if (!auth.currentUser) return

        const snapshot = await getDocs(
          query(
            collection(db, 'users', auth.currentUser.uid, 'notifications'),
            where('status', '==', 'unread')
          )
        )
        
        if (snapshot.empty) return

        const batch = writeBatch(db)
        snapshot.docs.forEach(doc => {
          batch.update(doc.ref, { status: 'read' })
        })

        await batch.commit()
        this.resetNotifications()
        this.setupRealtimeListener()
      } catch (error) {
        console.error('Error marking all as read:', error)
      }
    },

    formatDate(date) {
      if (typeof date === 'string') {
        return moment(date).fromNow()
      }
      
      if (date.toDate) {
        return moment(date.toDate()).fromNow()
      }

      return moment(date).fromNow()
    },

    toggleMessage(id) {
      this.$set(this.expandedMessages, id, !this.expandedMessages[id])
    },

    handleNotificationClick(notification) {
      if (notification.url) {
        this.$router.push(notification.url)
      }
      this.showNotifications = false
    },

    resetNotifications() {
      this.notifications = []
      this.lastDoc = null
      this.infiniteId = +new Date()
    },

    getNotificationIcon(notification) {
      try {
        return notification.icon || require('@/assets/img/fly-new-logo.png')
      } catch (error) {
        console.error('Error loading notification icon:', error)
        return require('@/assets/img/fly-new-logo.png')
      }
    },

    handleImageError(event) {
      event.target.src = require('@/assets/img/fly-new-logo.png')
    },

    handleOutsideClick() {
      this.resetNotifications()
    }
  },

  watch: {
    showNotifications(newVal) {
      if (newVal) {
        this.activeTab = 'unread'
        this.cleanup()
        this.resetNotifications()
        this.initializeNotifications()
      }
    },
    
    activeTab() {
      this.cleanup()
      this.resetNotifications()
      this.initializeNotifications()
    }
  }
}
</script>

<style scoped>
.notification-panel {
  max-height: 75vh;
  width: 400px;
  position: relative;
}

@media (max-width: 350px) {
  .notification-panel {
    max-height: 85vh;
    width: 100vw;
  }

  .notifications-container {
    max-height: calc(85vh - 140px);
  }
}

.notification-header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
  border-bottom: 1px solid #eee;
}

.tabs-container {
  border-bottom: 1px solid #eee;
  padding: 5px 0px;
  height: 52px;
  display: flex;
  align-items: center;
}

.tab-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  height: 100%;
}

.tab-btn {
  height: 100%;
  padding: 0 1.5rem;
  color: #666;
  border-bottom: 2px solid transparent;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  max-width: 150px;
}

@media (max-width: 350px) {
  .tabs-container {
    height: 44px;
  }

  .tab-buttons {
    gap: 0.5rem;
  }

  .tab-btn {
    padding: 0 1rem;
    min-width: 80px;
  }
}

.tab-btn.active {
  color: #000;
  border-bottom-color: #8050DE;
}

.notifications-container {
  max-height: calc(75vh - 140px);
  overflow-y: auto;
  padding-bottom: 50px;
}

.notification-item {
  border-bottom: 1px solid #eee;
}

.notification-item:hover {
  background-color: #f5f5f5;
}

.truncated {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.mark-all-button {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-top: 1px solid #eee;
  padding: 0.75rem;
  text-align: center;
  z-index: 2;
}

.notifications-container::-webkit-scrollbar {
  width: 6px;
}

.notifications-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.notifications-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.notifications-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>

<template>
  <div>
    <!--  -->
    <div class="w-full items-center mx-auto px-4 my-8">
      <div class="pb-0 mb-8 flex">
        <p class="mt-2 text-lg font-semibold text-[#121212]">
          Competitor Analysis
        </p>
      </div>
      <div
        class="relative flex justify-between flex-col md:flex-row items-start gap-6 md:items-center md:gap-2 grow"
      >
        <div class="gap-2 w-full md:w-1/3">
          <div class="relative">
            <v-text-field
              v-model="locationSearch"
              label="Locations"
              hide-details
              dense
              outlined
              @click="toggleStoreDropdown($event)"
              class="location-input"
              color="rgba(0, 0, 0, 0.6)"
              background-color="white"
              :style="{
                '& .v-label': {
                  top: '6px',
                  fontSize: '14px',
                },
              }"
            >
              <template v-slot:append>
                <button
                  @click="toggleStoreDropdown($event)"
                  class="absolute inset-y-0 right-0 flex items-center px-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
              </template>
            </v-text-field>
            <div
              ref="showStoreDropdown"
              v-if="showStoreDropdown"
              class="absolute top-full left-0 mt-1 bg-white border border-gray-300 rounded shadow-lg z-50 max-h-60 max-w-[800px] overflow-y-auto px-5"
            >
              <div
                v-for="profile in filteredStores"
                :key="profile.wid"
                class="platform p-2"
              >
                <div class="flex items-center gap-2">
                  <input
                    type="radio"
                    :value="profile.wid"
                    v-model="selectedWID"
                    @change="fetchCompetitorData(profile)"
                  />
                  <div class="flex flex-col">
                    <span
                      class="font-medium text-base"
                      v-if="profile.sub_locality"
                      >{{ profile.sub_locality }}</span
                    >
                    <span class="text-sm">{{ profile.city }}</span>
                    <span class="text-sm">{{ profile.state }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template v-if="isLoadingCompetitors">
      <v-skeleton-loader
        type="table"
        class="mb-4"
        boilerplate
      ></v-skeleton-loader>

      <!-- <div v-for="i in 4" :key="i" class="border-b border-gray-200">
        <div class="flex items-center w-full text-xs bg-white py-3">
          <div class="w-1/3 px-2">
            <div class="skeleton-loader w-32"></div>
          </div>
          <div class="w-1/6 text-center">
            <div class="skeleton-loader w-8 mx-auto"></div>
          </div>
          <div class="w-1/6 text-center">
            <div class="skeleton-loader w-16 mx-auto"></div>
          </div>
          <div class="w-1/6 text-center">
            <div class="skeleton-loader w-12 mx-auto"></div>
          </div>
          <div class="w-1/6 text-start px-2">
            <div class="skeleton-loader w-24"></div>
          </div>
        </div>
      </div> -->
    </template>
    <template v-else>
      <div class="px-3 mx-2 my-8 w-full">
        <div class="bg-white p-4 rounded-lg shadow-md mt-4 w-[98%]">
          <div class="overflow-x-auto">
            <div class="min-w-[800px]">
              <div class="bg-gray-100 rounded-t-lg text-xs w-full">
                <div class="flex items-center text-gray-600 p-2">
                  <div
                    class="w-1/3 text-start px-2 cursor-pointer hover:bg-gray-200"
                  >
                    Business Name
                  </div>
                  <div
                    class="w-1/6 text-center px-2 cursor-pointer hover:bg-gray-200"
                  >
                    Avg. Rank
                  </div>
                  <div
                    class="w-1/6 text-center px-2 cursor-pointer hover:bg-gray-200"
                  >
                    Rating
                  </div>
                  <div
                    class="w-1/6 text-center px-2 cursor-pointer hover:bg-gray-200"
                  >
                    Total Ratings
                  </div>
                  <div class="w-1/6 text-start px-2">Category</div>
                </div>
              </div>

              <!-- Sample static data -->
              <div
                v-for="(competitor, index) in processedCompetitors"
                :key="index"
                class="border-b border-gray-200"
              >
                <div>
                  <div
                    class="flex items-center w-full text-xs bg-white hover:bg-gray-100 py-3 cursor-pointer"
                    @click="toggleCompetitorDetails(competitor.place_id)"
                  >
                    <div class="w-1/3 px-2">
                      <div class="flex items-center gap-2">
                        <span
                          class="font-medium"
                          :class="{
                            'text-blue-500 font-semibold':
                              competitor.is_your_business,
                          }"
                          >{{ competitor.name }}</span
                        >
                        <v-icon small>
                          {{
                            selectedCompetitorId === competitor.place_id
                              ? "mdi-chevron-up"
                              : "mdi-chevron-down"
                          }}
                        </v-icon>
                      </div>
                    </div>
                    <div class="w-1/6 text-center font-medium">
                      {{ competitor.avgRank }}
                    </div>
                    <div class="w-1/6 text-center">
                      <div v-if="competitor.is_your_business">--</div>
                      <div
                        v-else
                        class="flex items-center justify-center gap-1"
                      >
                        <span class="font-medium">{{ competitor.rating }}</span>
                        <v-icon small color="amber">mdi-star</v-icon>
                      </div>
                    </div>
                    <div class="w-1/6 text-center font-medium">
                      {{
                        competitor.is_your_business
                          ? "--"
                          : competitor.totalRatings
                      }}
                    </div>
                    <div class="w-1/6 text-start px-2 text-gray-600">
                      {{ competitor.category }}
                    </div>
                  </div>

                  <!-- Keyword-specific ranks dropdown -->
                  <div
                    v-if="selectedCompetitorId === competitor.place_id"
                    class="bg-gray-50 px-4 py-2 text-xs"
                  >
                    <div class="font-medium mb-2">Keyword Rankings:</div>
                    <div
                      v-for="(keywordRank, idx) in competitor.keywordRanks"
                      :key="idx"
                      class="grid grid-cols-2 gap-4 py-1"
                    >
                      <div>{{ keywordRank.keyword }}</div>
                      <div class="flex items-center gap-2">
                        <span>Rank: {{ keywordRank.rank }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  collection,
  onSnapshot,
  query,
  where,
  getDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/utils/firebase.utils";
import { auth, db } from "@/utils/firebase.utils";
import ToolTip from "@/components/ui/ToolTip.vue";
import Chart from "chart.js/auto";
import { map } from "lodash";

export default {
  components: { ToolTip },
  props: {},
  data() {
    return {
      stores: [],
      showStoreDropdown: false,
      selectedWID: null,
      isLoading: false,
      sortBy: "location",
      sortDesc: false,
      selectedCompetitorId: null,
      processedCompetitors: [],
      selectedCompetitorRanks: null, // For dropdown
      isLoadingCompetitors: false,
      locationSearch: "",
    };
  },
  computed: {
    filteredStores() {
      if (!this.locationSearch) {
        return this.stores;
      }

      const searchTerm = this.locationSearch.toLowerCase();
      return this.stores.filter(
        (store) =>
          store.name?.toLowerCase().includes(searchTerm) ||
          store.sub_locality?.toLowerCase().includes(searchTerm) ||
          store.city?.toLowerCase().includes(searchTerm) ||
          store.state?.toLowerCase().includes(searchTerm)
      );
    },
  },
  watch: {},
  async created() {},
  methods: {
    processCompetitors(data) {
      // Create a map to store all ranks for each competitor
      const competitorMap = new Map();

      // Process each keyword's competitors
      data.data.forEach((keywordData) => {
        keywordData.competitors.forEach((comp) => {
          if (!competitorMap.has(comp.place_id)) {
            competitorMap.set(comp.place_id, {
              name: comp.name,
              address: comp.address,
              rating: comp.rating,
              totalRatings: comp.user_ratings_total,
              category: comp.types[0],
              keywordRanks: [],
              place_id: comp.place_id,
              is_your_business: comp.is_your_business || false,
            });
          }

          // Add rank for this keyword
          competitorMap.get(comp.place_id).keywordRanks.push({
            keyword: keywordData.keyword,
            rank: comp.scraper_rank,
            matched: comp.matched,
          });
        });
      });

      // Convert map to array and calculate average ranks
      this.processedCompetitors = Array.from(competitorMap.values()).map(
        (comp) => ({
          ...comp,
          avgRank: (
            comp.keywordRanks.reduce((acc, curr) => acc + curr.rank, 0) /
            comp.keywordRanks.length
          ).toFixed(1),
        })
      );

      // Sort by average rank
    },

    toggleCompetitorDetails(placeId) {
      this.selectedCompetitorId =
        this.selectedCompetitorId === placeId ? null : placeId;
    },

    // Update existing sortData method
    sortData(column) {
      if (this.sortBy === column) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = column;
        this.sortDesc = false;
      }

      this.processedCompetitors.sort((a, b) => {
        let aVal = a[column];
        let bVal = b[column];

        if (typeof aVal === "number" && typeof bVal === "number") {
          return this.sortDesc ? bVal - aVal : aVal - bVal;
        }

        aVal = String(aVal).toLowerCase();
        bVal = String(bVal).toLowerCase();
        return this.sortDesc ? (bVal < aVal ? -1 : 1) : aVal < bVal ? -1 : 1;
      });
    },

    handleClickOutside(event) {
      const profileDropdown = this.$refs.showStoreDropdown;
      if (profileDropdown && !profileDropdown.contains(event.target)) {
        this.closeProfileDropdown();
      }
    },
    closeProfileDropdown() {
      if (this.showStoreDropdown) {
        this.showStoreDropdown = false;
      }
    },
    toggleStoreDropdown(event) {
      this.showStoreDropdown = !this.showStoreDropdown;
      event.stopPropagation();
    },
    async fetchStores() {
      const uid = this.currentUID;
      const storeRef = collection(db, "users", uid, "fly_workspaces");
      onSnapshot(storeRef, async (querySnapshot) => {
        this.stores = [];
        querySnapshot.forEach((doc) => {
          let storeData = doc.data();
          if (
            storeData.storeWorkspace &&
            storeData.admin === this.WID &&
            storeData.connected_apps?.includes("google_business_profile")
          ) {
            storeData.wid = doc.id;
            storeData.type = "store";
            storeData.display_name = `${storeData.sub_locality || ""} - ${
              storeData.name || ""
            }`;
            this.stores.push(storeData);
          }
        });

        // Select first store and fetch its competitor data
        if (this.stores.length > 0) {
          const firstStore = this.stores[0];
          this.selectedWID = firstStore.wid;
          await this.fetchCompetitorData(firstStore);
        }
      });
    },
    async fetchCompetitorData(store) {
      try {
        if (!store || !store.wid) {
          console.error("Invalid store data");
          return;
        }
        this.isLoadingCompetitors = true;
        this.$loader.show();
        const uid = this.currentUID;
        const payload = {
          uid,
          wid: store.wid,
        };

        const fetchCompetitorsData = httpsCallable(
          functions,
          "fetchCompetitors",
          {
            timeout: 540000,
          }
        );
        const result = await fetchCompetitorsData(payload);
        console.log("result:", result.data);
        console.log("resultd:", result.data.data);

        // Ensure we have data before assigning
        if (result && result.data) {
          this.competitors = result.data;
          this.processCompetitors(result.data);
          //   this.sortData(this.sortBy);
        } else {
          console.warn("No competitor data received");
          this.competitors = []; // Set empty array as fallback
        }
      } catch (error) {
        console.error("Error fetching competitor data:", error);
        this.competitors = []; // Set empty array on error
      } finally {
        this.isLoadingCompetitors = false;
        this.$loader.hide();
      }
    },
  },

  async mounted() {
    // this.fetchGMBLogo();
    await this.fetchStores();
    // this.fetchChartMetrics();
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
.text-xs {
  font-size: 0.75rem;
}
:deep(.v-text-field.location-input) {
  .v-input__slot {
    min-height: 40px !important;
  }

  .v-text-field__placeholder {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
  }

  input {
    font-size: 14px;
  }

  fieldset {
    border-color: rgba(0, 0, 0, 0.6) !important;
  }
}
.text-center {
  text-align: center;
}
img {
  object-fit: cover;
}
.cursor-pointer {
  cursor: pointer;
}
.hover\:bg-gray-100:hover {
  background-color: #f7fafc;
}
.leading-tight {
  line-height: 1.25;
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
}
.skeleton-loader {
  display: inline-block;
  width: 50px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: skeleton-loading 1.5s infinite;
}
.overflow-x-auto {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
}
/* Optional: Custom scrollbar styling */
.overflow-x-auto::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.overflow-x-auto::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.overflow-x-auto::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.overflow-x-auto::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tooltip-container {
  position: relative;
  z-index: 40;
}
.v-tooltip__content {
  z-index: 60 !important;
  position: fixed !important;
}
.v-icon {
  z-index: 40;
  position: relative;
}

.showStoreDropdown {
  background-color: white; /* Ensure solid background */
  z-index: 50;
  position: relative;
}

.date-select {
  position: relative;
  z-index: 40;
}

button.tabs {
  border-width: 2px;
  border-style: solid;
  border-color: #8056de;
}
button.tabs.active {
  background-color: #8056de;
  color: white;
}
.metrics-select {
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* This ensures the v-select dropdown menu appears above everything */
:deep(.v-menu__content) {
  z-index: 60 !important;
}
</style>

<template>
  <div>
    <!--  -->
    <div class="w-full items-center mx-auto px-4 my-8">
      <div
        class="relative flex justify-between flex-col md:flex-row items-start gap-6 md:items-center md:gap-2 grow"
      >
        <div class="gap-2 w-full md:w-1/3">
          <div class="relative">
            <v-text-field
              v-model="locationSearch"
              label="Locations"
              hide-details
              dense
              outlined
              @click="toggleStoreDropdown($event)"
              @input="filteredStores"
              class="location-input"
              color="rgba(0, 0, 0, 0.6)"
              background-color="white"
              :style="{
                '& .v-label': {
                  top: '6px',
                  fontSize: '14px',
                },
              }"
            >
              <template v-slot:append>
                <button
                  @click="toggleStoreDropdown($event)"
                  class="absolute inset-y-0 right-0 flex items-center px-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
              </template>
            </v-text-field>
            <div
              ref="showStoreDropdown"
              v-if="showStoreDropdown"
              class="absolute top-full left-0 mt-1 bg-white border border-gray-300 rounded shadow-lg z-50 max-h-60 max-w-[800px] overflow-y-auto px-5"
            >
              <div
                v-for="profile in filteredStores"
                :key="profile.wid"
                class="platform p-2"
              >
                <div class="flex items-center gap-2">
                  <input
                    type="checkbox"
                    v-model="profile.checked"
                    @change="fetchStorePosts(profile)"
                  />
                  <div class="flex flex-col">
                    <span
                      class="font-medium text-base"
                      v-if="profile.sub_locality"
                      >{{ profile.sub_locality }}</span
                    >
                    <span class="text-sm">{{ profile.city }}</span>
                    <span class="text-sm">{{ profile.state }}</span>
                  </div>
                  <!-- </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-select
          v-model="selectedDate"
          :items="dateOptions"
          label="Select Month"
          dense
          outlined
          :loading="isLoadingDates"
          class="min-w-[200px] flex-1 sm:flex-none date-select"
          hide-details
          @change="selectDateRange"
        ></v-select>
      </div>
    </div>

    <div class="p-4 pb-0 flex">
      <p class="mt-2 text-lg font-semibold text-[#121212]">Aggregated Stats</p>
    </div>
    <div class="px-3 mx-2 my-8 w-full">
      <div class="bg-white p-4 rounded-lg shadow-md mt-4 w-[98%]">
        <div class="overflow-x-auto">
          <div class="min-w-[1000px]">
            <div class="flex justify-between">
              <div class="flex-1">
                <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                  Maps
                  <tool-tip
                    :message="getTooltipMessage('maps', 'combined')"
                    class="tooltip-container"
                  >
                    <v-icon small>mdi-information</v-icon>
                  </tool-tip>
                </p>
                <p class="text-lg font-bold whitespace-nowrap">
                  <span v-if="!isLoading && metricsData?.length > 0">{{
                    getTotalMetric("maps") ? getTotalMetric("maps") : "0"
                  }}</span>
                  <span v-else-if="isLoading" class="skeleton-loader"></span>
                  <span v-else>--</span>
                </p>
              </div>
              <div class="border-l border-gray-300 mx-4"></div>
              <div class="flex-1">
                <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                  Search
                  <tool-tip :message="getTooltipMessage('search', 'combined')">
                    <v-icon small>mdi-information</v-icon>
                  </tool-tip>
                </p>
                <p class="text-lg font-bold whitespace-nowrap">
                  <span v-if="!isLoading && metricsData?.length > 0">{{
                    getTotalMetric("search") ? getTotalMetric("search") : "0"
                  }}</span>
                  <span v-else-if="isLoading" class="skeleton-loader"></span>
                  <span v-else>--</span>
                </p>
              </div>
              <div class="border-l border-gray-300 mx-4"></div>
              <div class="flex-1">
                <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                  Direction Requests
                  <tool-tip
                    :message="getTooltipMessage('directions', 'combined')"
                  >
                    <v-icon small>mdi-information</v-icon>
                  </tool-tip>
                </p>
                <p class="text-lg font-bold whitespace-nowrap">
                  <span v-if="!isLoading && metricsData?.length > 0">{{
                    getTotalMetric("directions")
                      ? getTotalMetric("directions")
                      : "0"
                  }}</span>
                  <span v-else-if="isLoading" class="skeleton-loader"></span>
                  <span v-else>--</span>
                </p>
              </div>
              <div class="border-l border-gray-300 mx-4"></div>
              <div class="flex-1">
                <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                  Call Clicks
                  <tool-tip :message="getTooltipMessage('calls', 'combined')">
                    <v-icon small>mdi-information</v-icon>
                  </tool-tip>
                </p>
                <p class="text-lg font-bold whitespace-nowrap">
                  <span v-if="!isLoading && metricsData?.length > 0">{{
                    getTotalMetric("calls") ? getTotalMetric("calls") : "0"
                  }}</span>
                  <span v-else-if="isLoading" class="skeleton-loader"></span>
                  <span v-else>--</span>
                </p>
              </div>
              <div class="border-l border-gray-300 mx-4"></div>
              <div class="flex-1">
                <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                  Website Clicks
                  <tool-tip :message="getTooltipMessage('website', 'combined')">
                    <v-icon small>mdi-information</v-icon>
                  </tool-tip>
                </p>
                <p class="text-lg font-bold whitespace-nowrap">
                  <span v-if="!isLoading && metricsData?.length > 0">{{
                    getTotalMetric("website") ? getTotalMetric("website") : "0"
                  }}</span>
                  <span v-else-if="isLoading" class="skeleton-loader"></span>
                  <span v-else>--</span>
                </p>
              </div>
              <div class="border-l border-gray-300 mx-4"></div>
              <div class="flex-1">
                <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                  Bookings
                  <tool-tip
                    :message="getTooltipMessage('bookings', 'combined')"
                  >
                    <v-icon small>mdi-information</v-icon>
                  </tool-tip>
                </p>
                <p class="text-lg font-bold whitespace-nowrap">
                  <span v-if="!isLoading && metricsData?.length > 0">{{
                    getTotalMetric("bookings")
                      ? getTotalMetric("bookings")
                      : "0"
                  }}</span>
                  <span v-else-if="isLoading" class="skeleton-loader"></span>
                  <span v-else>--</span>
                </p>
              </div>
              <div class="border-l border-gray-300 mx-4"></div>
              <div class="flex-1">
                <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                  Food Orders
                  <tool-tip
                    :message="getTooltipMessage('food_orders', 'combined')"
                  >
                    <v-icon small>mdi-information</v-icon>
                  </tool-tip>
                </p>
                <p class="text-lg font-bold whitespace-nowrap">
                  <span v-if="!isLoading && metricsData?.length > 0">{{
                    getTotalMetric("food_orders")
                      ? getTotalMetric("food_orders")
                      : "0"
                  }}</span>
                  <span v-else-if="isLoading" class="skeleton-loader"></span>
                  <span v-else>--</span>
                </p>
              </div>
              <div class="border-l border-gray-300 mx-4"></div>
              <div class="flex-1">
                <p class="text-sm font-medium text-gray-700 whitespace-nowrap">
                  Food Menu Clicks
                  <tool-tip
                    :message="getTooltipMessage('food_menu', 'combined')"
                  >
                    <v-icon small>mdi-information</v-icon>
                  </tool-tip>
                </p>
                <p class="text-lg font-bold whitespace-nowrap">
                  <span v-if="!isLoading && metricsData?.length > 0">{{
                    getTotalMetric("food_menu")
                      ? getTotalMetric("food_menu")
                      : "0"
                  }}</span>
                  <span v-else-if="isLoading" class="skeleton-loader"></span>
                  <span v-else>--</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Tabs -->
    <div class="px-4 mt-9 mb-8 pt-4">
      <div class="flex gap-4 text-sm md:text-base max-sm:justify-between">
        <button
          @click="handleTabChange('performance')"
          :class="[
            'tabs py-1 px-4 font-medium border hover:border-[#8250e8] hover:bg-[#7b68b6] hover:text-white transition-colors duration-200 rounded-full',
            activeTab === 'performance'
              ? 'bg-[#8056DE] text-white'
              : 'bg-white text-black',
          ]"
        >
          Store Performance
        </button>
        <button
          @click="handleTabChange('metrics')"
          :class="[
            'tabs py-1 px-4 font-medium hover:border-[#8250e8] rounded-full transition-colors duration-200 hover:bg-[#7b68b6] hover:text-white',
            activeTab === 'metrics'
              ? 'bg-[#8056DE] text-white'
              : 'bg-white text-black',
          ]"
        >
          Store Metrics
        </button>
      </div>
    </div>
    <!--  -->
    <div
      v-if="activeTab === 'performance'"
      class="flex flex-col items-center justify-center w-full pb-20"
    >
      <section
        class="flex flex-col items-center w-full justify-center xl:w-[100%]"
      >
        <div class="w-full xl:w-[100%] flex justify-center items-center gap-4">
          <div class="w-full bg-white">
            <div class="flex flex-col justify-between w-full px-5 mx-auto">
              <!-- <p class="mt-2 text-lg font-semibold text-[#121212]">
                Store Performance
              </p> -->

              <div class="p-4 bg-white shadow-lg rounded-lg max-w-[1900px]">
                <div class="overflow-x-auto w-full">
                  <div v-if="isLoading">
                    <v-skeleton-loader
                      type="table"
                      class="mb-4"
                      boilerplate
                    ></v-skeleton-loader>
                  </div>
                  <div
                    v-else-if="!metricsData || metricsData?.length === 0"
                    class="flex justify-center items-center py-8"
                  >
                    <p class="text-gray-500">No data available</p>
                  </div>
                  <div v-else class="">
                    <div
                      class="bg-gray-100 min-w-[1000px] rounded-t-lg text-xs w-full"
                    >
                      <div
                        class="flex items-center justify-between text-gray-600 p-2 flex-wrap"
                      >
                        <div
                          class="w-1/6 text-start whitespace-nowrap px-2 cursor-pointer hover:bg-gray-200"
                          @click="sortData('location')"
                        >
                          Location
                          <v-icon small>
                            {{
                              sortBy === "location"
                                ? sortDesc
                                  ? "mdi-arrow-down"
                                  : "mdi-arrow-up"
                                : "mdi-arrow-up"
                            }}
                          </v-icon>
                        </div>
                        <div
                          class="w-1/12 text-center whitespace-nowrap px-2 cursor-pointer hover:bg-gray-200"
                          @click="sortData('desktop_maps_impressions')"
                        >
                          Desktop<br />
                          Maps
                          <!-- <v-icon small class="pr-1">mdi-web</v-icon> -->
                          <tool-tip
                            :message="getTooltipMessage('maps', 'desktop')"
                          >
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                          <v-icon small>
                            {{
                              sortBy === "desktop_maps_impressions"
                                ? sortDesc
                                  ? "mdi-arrow-down"
                                  : "mdi-arrow-up"
                                : "mdi-arrow-up"
                            }}
                          </v-icon>
                        </div>
                        <div
                          class="w-1/12 text-center whitespace-nowrap px-2 cursor-pointer hover:bg-gray-200"
                          @click="sortData('mobile_maps_impressions')"
                        >
                          Mobile<br />
                          Maps

                          <!-- Maps <v-icon small>mdi-cellphone</v-icon> -->
                          <tool-tip
                            :message="getTooltipMessage('maps', 'mobile')"
                          >
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                          <v-icon small>
                            {{
                              sortBy === "mobile_maps_impressions"
                                ? sortDesc
                                  ? "mdi-arrow-down"
                                  : "mdi-arrow-up"
                                : "mdi-arrow-up"
                            }}
                          </v-icon>
                        </div>
                        <div
                          class="w-1/12 text-center whitespace-nowrap px-2"
                          @click="sortData('desktop_search_impressions')"
                        >
                          Desktop<br />
                          Search
                          <!-- Search <v-icon small class="pr-1">mdi-web</v-icon> -->
                          <tool-tip
                            :message="getTooltipMessage('search', 'desktop')"
                          >
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                          <v-icon small>
                            {{
                              sortBy === "desktop_search_impressions"
                                ? sortDesc
                                  ? "mdi-arrow-down"
                                  : "mdi-arrow-up"
                                : "mdi-arrow-up"
                            }}
                          </v-icon>
                        </div>
                        <div
                          class="w-1/12 text-center whitespace-nowrap px-2"
                          @click="sortData('mobile_search_impressions')"
                        >
                          Mobile<br />
                          Search
                          <!-- Search
                          <v-icon small class="pr-1">mdi-cellphone</v-icon> -->
                          <tool-tip
                            :message="getTooltipMessage('search', 'mobile')"
                          >
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                          <v-icon small>
                            {{
                              sortBy === "mobile_search_impressions"
                                ? sortDesc
                                  ? "mdi-arrow-down"
                                  : "mdi-arrow-up"
                                : "mdi-arrow-up"
                            }}
                          </v-icon>
                        </div>
                        <div
                          class="w-1/12 text-center whitespace-nowrap px-2 cursor-pointer hover:bg-gray-200"
                          @click="sortData('direction_requests')"
                        >
                          Direction<br class="d-xl-none" />
                          Requests
                          <tool-tip :message="getTooltipMessage('directions')">
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                          <v-icon small>
                            {{
                              sortBy === "direction_requests"
                                ? sortDesc
                                  ? "mdi-arrow-down"
                                  : "mdi-arrow-up"
                                : "mdi-arrow-up"
                            }}
                          </v-icon>
                        </div>
                        <div
                          class="w-1/12 text-center whitespace-nowrap px-5 cursor-pointer hover:bg-gray-200"
                          @click="sortData('call_clicks')"
                        >
                          Call<br />
                          Clicks
                          <tool-tip :message="getTooltipMessage('calls')">
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                          <v-icon small>
                            {{
                              sortBy === "call_clicks"
                                ? sortDesc
                                  ? "mdi-arrow-down"
                                  : "mdi-arrow-up"
                                : "mdi-arrow-up"
                            }}
                          </v-icon>
                        </div>
                        <div
                          class="w-1/12 text-center whitespace-nowrap px-2 cursor-pointer hover:bg-gray-200"
                          @click="sortData('website_clicks')"
                        >
                          Website <br />
                          Clicks
                          <tool-tip :message="getTooltipMessage('website')">
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                          <v-icon small>
                            {{
                              sortBy === "website_clicks"
                                ? sortDesc
                                  ? "mdi-arrow-down"
                                  : "mdi-arrow-up"
                                : "mdi-arrow-up"
                            }}
                          </v-icon>
                        </div>
                        <div
                          class="w-1/12 text-center whitespace-nowrap pr-6 cursor-pointer hover:bg-gray-200"
                          @click="sortData('bookings')"
                        >
                          Bookings
                          <tool-tip :message="getTooltipMessage('bookings')">
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                          <v-icon small>
                            {{
                              sortBy === "bookings"
                                ? sortDesc
                                  ? "mdi-arrow-down"
                                  : "mdi-arrow-up"
                                : "mdi-arrow-up"
                            }}
                          </v-icon>
                        </div>
                        <div
                          class="w-1/12 text-center whitespace-nowrap px-2 cursor-pointer hover:bg-gray-200"
                          @click="sortData('food_orders')"
                        >
                          Food <br />Orders
                          <tool-tip :message="getTooltipMessage('food_orders')">
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                          <v-icon small>
                            {{
                              sortBy === "food_orders"
                                ? sortDesc
                                  ? "mdi-arrow-down"
                                  : "mdi-arrow-up"
                                : "mdi-arrow-up"
                            }}
                          </v-icon>
                        </div>
                        <div
                          class="w-1/12 text-center whitespace-nowrap px-4 cursor-pointer hover:bg-gray-200"
                          @click="sortData('food_menu_clicks')"
                        >
                          Food Menu <br />Clicks
                          <tool-tip :message="getTooltipMessage('food_menu')">
                            <v-icon small>mdi-information</v-icon>
                          </tool-tip>
                          <v-icon small>
                            {{
                              sortBy === "food_menu_clicks"
                                ? sortDesc
                                  ? "mdi-arrow-down"
                                  : "mdi-arrow-up"
                                : "mdi-arrow-up"
                            }}
                          </v-icon>
                        </div>
                      </div>
                    </div>

                    <div class="min-w-[1000px]">
                      <div
                        v-for="metric in metricsData"
                        :key="metric.location.wid"
                        class="border-b border-gray-200"
                      >
                        <div
                          v-if="
                            metric.location?.name ||
                            metric.location?.storeLocation
                          "
                          class="flex justify-between items-center w-full text-xs bg-white hover:bg-gray-100 py-2"
                        >
                          <div class="w-1/6 px-2">
                            <div class="flex flex-col items-start">
                              <span
                                v-if="metric.location?.sub_locality"
                                class="font-semibold"
                                >{{ metric.location.sub_locality }}</span
                              >
                              <span
                                v-else-if="
                                  !metric.location?.sub_locality &&
                                  metric.location?.storeLocation
                                "
                                class="text-[12px]"
                                >{{ metric.location.storeLocation }}</span
                              >
                              <div class="flex gap-1 flex-col items-start">
                                <div
                                  v-if="metric.location?.city"
                                  class="text-xs"
                                >
                                  {{ metric.location.city }}
                                </div>
                                <span
                                  v-if="metric.location?.state"
                                  class="text-xs"
                                  >{{ metric.location?.state }}</span
                                >
                              </div>
                            </div>
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.desktop_maps_impressions }}
                            <!-- Only show comparison if NOT 12 months -->
                            <div v-if="previousMetrics[metric.location.wid] && selectedDate?.months !== 12" 
                              :class="getPercentageClass(metric.desktop_maps_impressions, previousMetrics[metric.location.wid].desktop_maps_impressions)"
                              class="text-[10px] flex items-center justify-center gap-1"
                            >
                              <v-icon small>{{ getPercentageIcon(metric.desktop_maps_impressions, previousMetrics[metric.location.wid].desktop_maps_impressions) }}</v-icon>
                              {{ calculatePercentageChange(metric.desktop_maps_impressions, previousMetrics[metric.location.wid].desktop_maps_impressions) }}%
                            </div>
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.mobile_maps_impressions }}
                            <div v-if="previousMetrics[metric.location.wid] && selectedDate?.months !== 12"
                              :class="getPercentageClass(metric.mobile_maps_impressions, previousMetrics[metric.location.wid].mobile_maps_impressions)"
                              class="text-[10px] flex items-center justify-center gap-1"
                            >
                              <v-icon small>{{ getPercentageIcon(metric.mobile_maps_impressions, previousMetrics[metric.location.wid].mobile_maps_impressions) }}</v-icon>
                              {{ calculatePercentageChange(metric.mobile_maps_impressions, previousMetrics[metric.location.wid].mobile_maps_impressions) }}%
                            </div>
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.desktop_search_impressions }}
                            <div  v-if="previousMetrics[metric.location.wid] && selectedDate?.months !== 12" 
                              :class="getPercentageClass(metric.desktop_search_impressions, previousMetrics[metric.location.wid].desktop_search_impressions)"
                              class="text-[10px] flex items-center justify-center gap-1"
                            >
                              <v-icon small>{{ getPercentageIcon(metric.desktop_search_impressions, previousMetrics[metric.location.wid].desktop_search_impressions) }}</v-icon>
                              {{ calculatePercentageChange(metric.desktop_search_impressions, previousMetrics[metric.location.wid].desktop_search_impressions) }}%
                            </div>
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.mobile_search_impressions }}
                            <div  v-if="previousMetrics[metric.location.wid] && selectedDate?.months !== 12" 
                              :class="getPercentageClass(metric.mobile_search_impressions, previousMetrics[metric.location.wid].mobile_search_impressions)"
                              class="text-[10px] flex items-center justify-center gap-1"
                            >
                              <v-icon small>{{ getPercentageIcon(metric.mobile_search_impressions, previousMetrics[metric.location.wid].mobile_search_impressions) }}</v-icon>
                              {{ calculatePercentageChange(metric.mobile_search_impressions, previousMetrics[metric.location.wid].mobile_search_impressions) }}%
                            </div>
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.direction_requests }}
                            <div v-if="previousMetrics[metric.location.wid] && selectedDate?.months !== 12"
                              :class="getPercentageClass(metric.direction_requests, previousMetrics[metric.location.wid].direction_requests)"
                              class="text-[10px] flex items-center justify-center gap-1"
                            >
                              <v-icon small>{{ getPercentageIcon(metric.direction_requests, previousMetrics[metric.location.wid].direction_requests) }}</v-icon>
                              {{ calculatePercentageChange(metric.direction_requests, previousMetrics[metric.location.wid].direction_requests) }}%
                            </div>
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.call_clicks }}
                            <div v-if="previousMetrics[metric.location.wid] && selectedDate?.months !== 12" 
                              :class="getPercentageClass(metric.call_clicks, previousMetrics[metric.location.wid].call_clicks)"
                              class="text-[10px] flex items-center justify-center gap-1"
                            >
                              <v-icon small>{{ getPercentageIcon(metric.call_clicks, previousMetrics[metric.location.wid].call_clicks) }}</v-icon>
                              {{ calculatePercentageChange(metric.call_clicks, previousMetrics[metric.location.wid].call_clicks) }}%
                            </div>
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.website_clicks }}
                            <div  v-if="previousMetrics[metric.location.wid] && selectedDate?.months !== 12" 
                              :class="getPercentageClass(metric.website_clicks, previousMetrics[metric.location.wid].website_clicks)"
                              class="text-[10px] flex items-center justify-center gap-1"
                            >
                              <v-icon small>{{ getPercentageIcon(metric.website_clicks, previousMetrics[metric.location.wid].website_clicks) }}</v-icon>
                              {{ calculatePercentageChange(metric.website_clicks, previousMetrics[metric.location.wid].website_clicks) }}%
                            </div>
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.bookings }}
                            <div  v-if="previousMetrics[metric.location.wid] && selectedDate?.months !== 12" 
                              :class="getPercentageClass(metric.bookings, previousMetrics[metric.location.wid].bookings)"
                              class="text-[10px] flex items-center justify-center gap-1"
                            >
                              <v-icon small>{{ getPercentageIcon(metric.bookings, previousMetrics[metric.location.wid].bookings) }}</v-icon>
                              {{ calculatePercentageChange(metric.bookings, previousMetrics[metric.location.wid].bookings) }}%
                            </div>
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.food_orders }}
                            <div  v-if="previousMetrics[metric.location.wid] && selectedDate?.months !== 12" 
                              :class="getPercentageClass(metric.food_orders, previousMetrics[metric.location.wid].food_orders)"
                              class="text-[10px] flex items-center justify-center gap-1"
                            >
                              <v-icon small>{{ getPercentageIcon(metric.food_orders, previousMetrics[metric.location.wid].food_orders) }}</v-icon>
                              {{ calculatePercentageChange(metric.food_orders, previousMetrics[metric.location.wid].food_orders) }}%
                            </div>
                          </div>
                          <div class="w-1/12 text-center font-semibold px-2">
                            {{ metric.food_menu_clicks }}
                            <div  v-if="previousMetrics[metric.location.wid] && selectedDate?.months !== 12" 
                              :class="getPercentageClass(metric.food_menu_clicks, previousMetrics[metric.location.wid].food_menu_clicks)"
                              class="text-[10px] flex items-center justify-center gap-1"
                            >
                              <v-icon small>{{ getPercentageIcon(metric.food_menu_clicks, previousMetrics[metric.location.wid].food_menu_clicks) }}</v-icon>
                              {{ calculatePercentageChange(metric.food_menu_clicks, previousMetrics[metric.location.wid].food_menu_clicks) }}%
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="selectedDate?.months && selectedDate.months !== 12"
                          class="flex items-center justify-start w-full px-2 py-1"
                        >
                          <button
                            @click="togglePreviousData(metric.location.wid)"
                            class="text-xs text-gray-600 hover:text-gray-800 flex items-center gap-1"
                          >
                            <v-icon small>{{ showPreviousData[metric.location.wid] ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                             Previous {{ selectedDate.months }} Months
                          </button>
                        </div>
                        <div
                          v-if="showPreviousData[metric.location.wid] && selectedDate.months !== 12"
                          class="bg-gray-50 px-2 py-3 border-t border-gray-200"
                        >
                          <div v-if="!previousMetrics[metric.location.wid]" class="flex justify-between items-center w-full text-xs">
                            <div class="w-1/6 px-2">
                              <div class="skeleton-loader w-24 h-4"></div>
                            </div>
                            <div class="w-1/12 text-center">
                              <div class="skeleton-loader w-12 h-4 mx-auto"></div>
                            </div>
                            <div class="w-1/12 text-center">
                              <div class="skeleton-loader w-12 h-4 mx-auto"></div>
                            </div>
                            <div class="w-1/12 text-center">
                              <div class="skeleton-loader w-12 h-4 mx-auto"></div>
                            </div>
                            <div class="w-1/12 text-center">
                              <div class="skeleton-loader w-12 h-4 mx-auto"></div>
                            </div>
                            <div class="w-1/12 text-center">
                              <div class="skeleton-loader w-12 h-4 mx-auto"></div>
                            </div>
                            <div class="w-1/12 text-center">
                              <div class="skeleton-loader w-12 h-4 mx-auto"></div>
                            </div>
                            <div class="w-1/12 text-center">
                              <div class="skeleton-loader w-12 h-4 mx-auto"></div>
                            </div>
                            <div class="w-1/12 text-center">
                              <div class="skeleton-loader w-12 h-4 mx-auto"></div>
                            </div>
                            <div class="w-1/12 text-center">
                              <div class="skeleton-loader w-12 h-4 mx-auto"></div>
                            </div>
                          </div>
                          <div v-else class="flex justify-between items-center w-full text-xs">
                            <!-- Your existing previous metrics display -->
                            <div class="w-1/6 px-2">
                              <span class="text-gray-600">Previous {{ selectedDate.months }} Months</span>
                            </div>
                            <div class="w-1/12 text-center">{{ previousMetrics[metric.location.wid].desktop_maps_impressions }}</div>
                            <div class="w-1/12 text-center">{{ previousMetrics[metric.location.wid].mobile_maps_impressions }}</div>
                            <div class="w-1/12 text-center">{{ previousMetrics[metric.location.wid].desktop_search_impressions }}</div>
                            <div class="w-1/12 text-center">{{ previousMetrics[metric.location.wid].mobile_search_impressions }}</div>
                            <div class="w-1/12 text-center">{{ previousMetrics[metric.location.wid].direction_requests }}</div>
                            <div class="w-1/12 text-center">{{ previousMetrics[metric.location.wid].call_clicks }}</div>
                            <div class="w-1/12 text-center">{{ previousMetrics[metric.location.wid].website_clicks }}</div>
                            <div class="w-1/12 text-center">{{ previousMetrics[metric.location.wid].bookings }}</div>
                            <div class="w-1/12 text-center">{{ previousMetrics[metric.location.wid].food_orders }}</div>
                            <div class="w-1/12 text-center">{{ previousMetrics[metric.location.wid].food_menu_clicks }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="flex justify-center mt-6">
        <div class="flex items-center gap-4">
          <button
            @click="prevPage"
            :disabled="currentPage === 1"
            class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border disabled:cursor-not-allowed border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
          >
            Previous
          </button>
          <div class="flex items-center gap-2">
            <span class="text-sm text-gray-700">Page</span>
            <span class="font-medium">{{ totalPages ? currentPage : 0 }}</span>
            <span class="text-sm text-gray-700">of</span>
            <span class="font-medium">{{ totalPages || 0 }}</span>
          </div>
          <button
            @click="nextPage"
            :disabled="currentPage >= totalPages"
            class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 hover:border hover:border-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Next
          </button>
        </div>
      </div>
    </div>
    <div
      v-else-if="activeTab === 'metrics'"
      class="bg-white p-4 rounded-lg shadow-md mt-4 w-full mx-2"
    >
      <div class="p-4 pb-0 gap-3 flex justify-between items-start">
        <!-- <p class="text-lg font-semibold text-[#121212]">Metrics Trends</p> -->
        <div
          class="flex gap-2 w-full sm:w-auto min-w-[200px] max-w-full text-sm md:text-base"
        >
          <v-select
            v-model="selectedMetrics"
            :items="availableMetrics"
            multiple
            chips
            label="Select Metrics"
            class="min-w-[200px] text-xs md:text-base"
            outlined
            deletable-chips
          ></v-select>
        </div>
      </div>
      <div
        v-if="chartData?.length"
        class="legend-container flex flex-wrap gap-2 mb-4 px-4"
      >
        <div
          v-for="metric in selectedMetrics"
          :key="metric"
          class="flex items-center gap-2"
        >
          <div
            class="w-6 h-[4px]"
            :style="{ backgroundColor: metricColors[metric] }"
          ></div>
          <span class="text-sm text-gray-600">
            {{ availableMetrics.find((m) => m.value === metric)?.text }}
          </span>
        </div>
      </div>
      <div
        v-if="isChartLoading"
        class="w-full h-20 flex justify-center items-center bg-white rounded-lg p-4"
      >
        <v-progress-circular
          model-value="20"
          color="primary"
          :size="30"
          indeterminate
        ></v-progress-circular>
      </div>
      <div
        v-else-if="!chartData || chartData?.length === 0"
        class="flex justify-center items-center py-8"
      >
        <p class="text-gray-500">No data available</p>
      </div>
      <div
        v-show="!isChartLoading && chartData?.length"
        class="relative h-[400px] w-full"
      >
        <canvas ref="metricsChart"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
class MetricsCache {
  constructor() {
    this.cache = new Map();
    this.initializeCache();
  }

  initializeCache() {
    try {
      const savedCache = null;
      const lastUpdated = null;

      // Clear cache if it's older than 12 hours
      if (this.isCacheExpired(lastUpdated)) {
        this.clear();
        return;
      }

      if (savedCache) {
        const parsedCache = JSON.parse(savedCache);
        Object.entries(parsedCache).forEach(([key, value]) => {
          this.cache.set(key, value);
        });
      }
    } catch (error) {
      console.error("Error initializing cache:", error);
      this.clear();
    }
  }

  isCacheExpired(timestamp) {
    if (!timestamp) return true;
    const CACHE_DURATION = 12 * 60 * 60 * 1000; // 12 hours in milliseconds
    return Date.now() - parseInt(timestamp) > CACHE_DURATION;
  }

  static generateLocationKey(months, wid) {
    return `${months}-${wid}`;
  }

  getLocationData(months, wid) {
    if (this.isCacheExpired(null)) {
      this.clear();
      return null;
    }
    const key = MetricsCache.generateLocationKey(months, wid);
    return this.cache.get(key);
  }

  getLocationsData(months, wids) {
    if (this.isCacheExpired(null)) {
      this.clear();
      return [];
    }
    return wids.map((wid) => this.getLocationData(months, wid)).filter(Boolean);
  }

  setLocationData(months, wid, data) {
    const key = MetricsCache.generateLocationKey(months, wid);
    this.cache.set(key, data);
    this.persistCache();
  }

  setLocationsData(months, metricsData) {
    metricsData.forEach((metric) => {
      if (metric.location?.wid) {
        this.setLocationData(months, metric.location.wid, metric);
      }
    });
  }
  // Add methods specifically for chart data
  static generateChartKey(months, wids) {
    const sortedWids = [...wids].sort().join("-");
    return `chart-${months}-${sortedWids}`;
  }

  getChartData(months, wids) {
    if (this.isCacheExpired(null)) {
      this.clear();
      return null;
    }
    const key = MetricsCache.generateChartKey(months, wids);
    return this.cache.get(key);
  }

  setChartData(months, wids, data) {
    const key = MetricsCache.generateChartKey(months, wids);
    this.cache.set(key, data);
    this.persistCache();
  }

  persistCache() {
    try {
      const cacheObject = Object.fromEntries(this.cache);
    } catch (error) {
      console.error("Error persisting cache:", error);
    }
  }

  debugCache() {
    const cacheContents = {};
    this.cache.forEach((value, key) => {
      cacheContents[key] = value;
    });
    console.log("Cache contents:", cacheContents);
    console.log("Cache age:", this.getCacheAge());
    return cacheContents;
  }

  getCacheAge() {
    const timestamp = null;
    if (!timestamp) return "Cache not initialized";
    const age = Date.now() - parseInt(timestamp);
    return `${(age / (60 * 60 * 1000)).toFixed(2)} hours`;
  }

  clear() {
    this.cache.clear();
    localStorage.removeItem("metricsCache");
    localStorage.removeItem("metricsCacheTimestamp");
  }
}
import {
  collection,
  onSnapshot,
  query,
  where,
  getDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/utils/firebase.utils";
import { auth, db } from "@/utils/firebase.utils";
import ToolTip from "@/components/ui/ToolTip.vue";
import Chart from "chart.js/auto";
import { map } from "lodash";

export default {
  components: { ToolTip },
  props: {},
  data() {
    return {
      stores: [],
      showStoreDropdown: false,
      selectedWID: null,
      selectedWIDs: [],
      metricsData: {},
      isLoading: false,
      isChartLoading: false,
      selectedDateRange: "Last 7 days",
      sortBy: "location",
      sortDesc: false,
      locationTitles: {},
      gmb_logo: "",
      dateOptions: [],
      selectedDate: null,
      isLoadingDates: false,
      metricsChart: null,
      chartData: [],
      selectedMetrics: [
        "desktop_maps_impressions",
        "mobile_maps_impressions",
        "desktop_search_impressions",
        "mobile_search_impressions",
        "direction_requests",
        "call_clicks",
        "website_clicks",
      ],
      availableMetrics: [
        { text: "Desktop Maps", value: "desktop_maps_impressions" },
        { text: "Mobile Maps", value: "mobile_maps_impressions" },
        { text: "Desktop Search", value: "desktop_search_impressions" },
        { text: "Mobile Search", value: "mobile_search_impressions" },
        { text: "Direction Requests", value: "direction_requests" },
        { text: "Call Clicks", value: "call_clicks" },
        { text: "Website Clicks", value: "website_clicks" },
        { text: "Bookings", value: "bookings" },
        { text: "Food Orders", value: "food_orders" },
        { text: "Food Menu Clicks", value: "food_menu_clicks" },
      ],
      activeTab: "performance",

      metricColors: {
        desktop_maps_impressions: "#2E86DE", // Blue
        mobile_maps_impressions: "#10AC84", // Green
        desktop_search_impressions: "#FF6B6B", // Red
        mobile_search_impressions: "#FFA502", // Orange
        direction_requests: "#8854D0", // Purple
        call_clicks: "#20BF6B", // Emerald Green
        website_clicks: "#EE5A24", // Dark Orange
        bookings: "#5758BB", // Indigo
        food_orders: "#D980FA", // Pink
        food_menu_clicks: "#B53471", // Dark Pink
      },
      locationSearch: "",
      currentPage: 1,
      itemsPerPage: 10,
      paginatedWIDs: [],
      metricsCache: new MetricsCache(),
      showPreviousData: {},
      previousMetrics: {},
    };
  },
  computed: {
    isCurrentMonth() {
      const currentDate = new Date();
      return (
        this.selectedDate?.year === currentDate.getFullYear() &&
        this.selectedDate?.month === currentDate.getMonth() + 1
      );
    },
    totalPages() {
      return Math.ceil(this.selectedWIDs.length / this.itemsPerPage) || 0;
    },
    filteredStores() {
      if (!this.locationSearch) {
        return this.stores;
      }

      const searchTerm = this.locationSearch.toLowerCase();
      return this.stores.filter(
        (store) =>
          store.name?.toLowerCase().includes(searchTerm) ||
          store.sub_locality?.toLowerCase().includes(searchTerm) ||
          store.city?.toLowerCase().includes(searchTerm) ||
          store.state?.toLowerCase().includes(searchTerm)
      );
    },
  },
  watch: {
    selectedWIDs(newVal) {
      if (!newVal.length) {
        this.currentPage = 1;
        this.updatePaginatedWIDs();
        this.fetchDataBasedOnTab();
        this.chartData = [];
        if (this.metricsChart) {
          this.metricsChart.destroy();
          this.metricsChart = null;
        }
      }
    },
    selectedMetrics() {
      this.updateChart();
    },
    activeTab(newVal) {
      if (newVal === "metrics") {
        // Ensure chart is rendered when switching to metrics tab
        this.$nextTick(() => {
          this.updateChart();
          this.$forceUpdate();
        });
      }
    },
    'selectedDate.months': {
      async handler(newVal) {
        // Reset previous data state
        this.showPreviousData = {};
        this.previousMetrics = {};
        
        // Reopen all dropdowns and fetch new data
        this.selectedWIDs.forEach(wid => {
          this.$set(this.showPreviousData, wid, true);
        });
        
        // Fetch previous metrics for all stores with new date range
        for (const wid of this.selectedWIDs) {
          await this.fetchPreviousMetrics(wid);
        }
      },
      immediate: true
    },
  },
  async created() {
    this.generateDateOptions();
  },
  methods: {
    updatePaginatedWIDs() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      // console.log('start:', start, 'end:', end)
      this.paginatedWIDs = this.selectedWIDs.slice(start, end);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.updatePaginatedWIDs();
        this.fetchDataBasedOnTab();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.updatePaginatedWIDs();
        this.fetchDataBasedOnTab();
      }
    },
    generateRandomPath() {
      // Generate random points for the path
      const points = [];
      const segments = 8;
      for (let i = 0; i <= segments; i++) {
        const x = (i * 100) / segments;
        const y = 150 + Math.random() * 100 - 50; // Random y value around 150
        points.push(`${x},${y}`);
      }
      return points.join(" L ");
    },
    async handleTabChange(tab) {
      if (this.metricsChart) {
        this.metricsChart.destroy();
        this.metricsChart = null;
      }
      this.activeTab = tab;
      await this.fetchDataBasedOnTab();
      this.$forceUpdate();
    },
    async fetchDataBasedOnTab() {
      if (this.activeTab === "performance") {
        await this.fetchMetricsForAllLocations();
      } else {
        await this.fetchChartMetrics();
      }
    },
    async fetchChartMetrics() {
      try {
        if (!this.selectedWIDs.length) {
          this.chartData = [];
          return;
        }

        this.isChartLoading = true;
        this.isLoading = true;
        const months = this.selectedDate.months;

        // Try to get data from cache first
        const cachedChartData = this.metricsCache.getChartData(
          months,
          this.selectedWIDs
        );

        if (cachedChartData) {
          this.chartData = this.deserializeChartData(cachedChartData);
          await this.updateChart();
          return;
        }
        // Initialize base payload
        let basePayload = {
          uid: this.currentUID,
          wid: this.selectedWIDs,
          accounts: [],
          year: this.selectedDate.year,
          month: this.selectedDate.month,
          source: "graph",
          range: this.selectedDate.months,
        };

        // Process all WIDs first
        const connectedAppsPromises = this.selectedWIDs.map(async (wid) => {
          const connectedAppsRef = collection(
            db,
            "users",
            this.currentUID,
            "fly_workspaces",
            wid,
            "connected-apps"
          );
          const gbpDoc = await getDocs(
            query(
              connectedAppsRef,
              where("__name__", "==", "google_business_profile")
            )
          );
          return { wid, gbpDoc };
        });

        // Wait for all promises to resolve
        const connectedAppsResults = await Promise.all(connectedAppsPromises);

        // Process all results before making the backend call
        for (const { wid, gbpDoc } of connectedAppsResults) {
          if (gbpDoc.empty) continue;

          const gbpData = gbpDoc.docs[0].data();
          const accounts = gbpData.accounts;
          if (!accounts?.length) continue;

          const accountId = accounts[0].id || "";

          // Process all accounts for this WID
          for (const account of accounts) {
            const locations = account.locations;
            if (!locations?.length) continue;

            // Process all locations for this account
            for (const location of locations) {
              basePayload.accounts.push({
                locationId: location.name,
                account: accountId,
                wid,
              });
            }
          }
        }

        // Only proceed if we have accounts to process
        if (basePayload.accounts.length > 0) {
          console.log("Final basePayload:", basePayload);
          const fetchMetricsGraph = httpsCallable(
            functions,
            "fetchPlatformMetrics"
          );
          const result = await fetchMetricsGraph(basePayload);
          console.log("result:", result.data);

          if (result.data.success) {
            // this.chartData = result.data.data.metrics_by_date;
            const newChartData = result.data.data.metrics_by_date;

            // Cache the new data
            this.metricsCache.setChartData(
              months,
              this.selectedWIDs,
              this.serializeChartData(newChartData)
            );

            this.chartData = newChartData;
            console.log("chartData:", newChartData);
            await this.updateChart();
          } else {
            console.warn("No data returned from metrics API");
          }
        } else {
          console.warn("No accounts found to process");
        }
      } catch (error) {
        console.error("Error fetching chart metrics:", error);
      } finally {
        this.isChartLoading = false;
        this.isLoading = false;
      }
    },

    // Serialize chart data for storage
    serializeChartData(data) {
      return data.map((dayData) => ({
        date: dayData.date,
        desktop_maps_impressions: dayData.desktop_maps_impressions,
        mobile_maps_impressions: dayData.mobile_maps_impressions,
        desktop_search_impressions: dayData.desktop_search_impressions,
        mobile_search_impressions: dayData.mobile_search_impressions,
        direction_requests: dayData.direction_requests,
        call_clicks: dayData.call_clicks,
        website_clicks: dayData.website_clicks,
        bookings: dayData.bookings,
        food_orders: dayData.food_orders,
        food_menu_clicks: dayData.food_menu_clicks,
      }));
    },

    // Deserialize chart data from storage
    deserializeChartData(data) {
      return data.map((dayData) => ({
        ...dayData,
        date: dayData.date, // Ensure date is properly handled
      }));
    },

    updateChart() {
      if (!this.chartData?.length || !this.selectedWIDs.length) {
        if (this.metricsChart) {
          this.metricsChart.destroy();
          this.metricsChart = null;
        }
        return;
      }

      const ctx = this.$refs.metricsChart.getContext('2d');
      
      // Sort chart data by date
      const sortedChartData = [...this.chartData].sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );

      // Enhanced color palette with gradients
      const createGradient = (color) => {
        const gradient = ctx.createLinearGradient(0, 0, 0, 400);
        gradient.addColorStop(0, `${color}20`); // More transparent at top
        gradient.addColorStop(1, `${color}00`); // Fully transparent at bottom
        return gradient;
      };

      // Updated color scheme with more professional colors
      const updatedColors = {
        desktop_maps_impressions: '#4C51BF', // Indigo
        mobile_maps_impressions: '#48BB78', // Green
        desktop_search_impressions: '#F56565', // Red
        mobile_search_impressions: '#ED8936', // Orange
        direction_requests: '#667EEA', // Purple
        call_clicks: '#38B2AC', // Teal
        website_clicks: '#9F7AEA', // Purple
        bookings: '#4299E1', // Blue
        food_orders: '#ED64A6', // Pink
        food_menu_clicks: '#805AD5', // Purple
      };

      const datasets = this.selectedMetrics.map((metric) => ({
        label: this.availableMetrics.find((m) => m.value === metric)?.text || metric,
        data: sortedChartData.map((day) => day[metric]),
        borderColor: updatedColors[metric],
        backgroundColor: createGradient(updatedColors[metric]),
        borderWidth: 2,
        pointBackgroundColor: updatedColors[metric],
        pointBorderColor: '#FFFFFF',
        pointBorderWidth: 2,
        pointRadius: 4,
        pointHoverRadius: 6,
        pointHoverBorderWidth: 3,
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: updatedColors[metric],
        tension: 0.3,
        fill: true,
      }));

      const config = {
        type: 'line',
        data: {
          labels: sortedChartData.map((day) => 
            new Date(day.date).toLocaleDateString('en-US', { 
              month: 'short', 
              day: 'numeric' 
            })
          ),
          datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            intersect: false,
            mode: 'index',
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              backgroundColor: 'rgba(255, 255, 255, 0.98)',
              titleColor: '#1a202c',
              bodyColor: '#4a5568',
              titleFont: {
                size: 14,
                weight: 'bold',
                family: "'Inter', sans-serif",
              },
              bodyFont: {
                size: 13,
                family: "'Inter', sans-serif",
              },
              padding: 12,
              borderColor: 'rgba(0,0,0,0.1)',
              borderWidth: 1,
              cornerRadius: 8,
              displayColors: true,
              boxWidth: 8,
              boxHeight: 8,
              boxPadding: 4,
              usePointStyle: true,
              callbacks: {
                title: function(tooltipItems) {
                  const date = new Date(sortedChartData[tooltipItems[0].dataIndex].date);
                  return date.toLocaleDateString('en-US', { 
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long', 
                    day: 'numeric'
                  });
                },
                label: function(context) {
                  let label = context.dataset.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed.y !== null) {
                    label += context.parsed.y.toLocaleString();
                  }
                  return label;
                }
              }
            }
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                font: {
                  size: 12,
                  family: "'Inter', sans-serif",
                },
                color: '#718096',
              },
            },
            y: {
              beginAtZero: true,
              grid: {
                color: 'rgba(0,0,0,0.05)',
                drawBorder: false,
              },
              ticks: {
                font: {
                  size: 12,
                  family: "'Inter', sans-serif",
                },
                color: '#718096',
                padding: 8,
                callback: function(value) {
                  if (value >= 1000000) {
                    return (value / 1000000).toFixed(1) + 'M';
                  } else if (value >= 1000) {
                    return (value / 1000).toFixed(1) + 'K';
                  }
                  return value;
                },
              },
            },
          },
          layout: {
            padding: {
              top: 20,
              right: 20,
              bottom: 20,
              left: 20
            }
          },
        },
      };

      // Destroy existing chart if it exists
      if (this.metricsChart) {
        this.metricsChart.destroy();
      }

      // Create new chart
      this.metricsChart = new Chart(ctx, config);
    },

    generateDateOptions() {
      this.dateOptions = [
        {
          text: "Last 3 Months",
          value: {
            months: 3,
          },
        },
        {
          text: "Last 6 Months",
          value: {
            months: 6,
          },
        },
        {
          text: "Last 1 Year",
          value: {
            months: 12,
          },
        },
      ];
      this.selectedDate = this.dateOptions[0].value;
    },
    sortData(column) {
      // If clicking the same column, reverse the sort direction
      if (this.sortBy === column) {
        this.sortDesc = !this.sortDesc;
      } else {
        // New column, set it as sort key and default to ascending
        this.sortBy = column;
        this.sortDesc = false;
      }
      console.log("metricsData:", this.metricsData);
      // Sort the metricsData array
      this.metricsData.sort((a, b) => {
        let aVal = column === "location" ? a.location.sub_locality : a[column];
        let bVal = column === "location" ? b.location.sub_locality : b[column];

        // Handle numeric values
        if (typeof aVal === "number" && typeof bVal === "number") {
          return this.sortDesc ? bVal - aVal : aVal - bVal;
        }

        // Handle string values
        aVal = String(aVal).toLowerCase();
        bVal = String(bVal).toLowerCase();
        if (aVal < bVal) return this.sortDesc ? 1 : -1;
        if (aVal > bVal) return this.sortDesc ? -1 : 1;
        return 0;
      });
    },
    async fetchGMBLogo() {
      if (this.gmb_logo) {
        return;
      }
      try {
        const platformDoc = await getDoc(
          doc(db, "fly_platforms", "google_business_profile")
        );
        if (platformDoc.exists()) {
          const logoUrl = platformDoc.data().logo_url;
          this.gmb_logo = logoUrl;
        }
      } catch (error) {
        console.error("Error fetching platform logo:", error);
      }
    },
    getTotalMetric(metricType) {
      // If no data is available, return null
      if (
        (!this.metricsData || !Array.isArray(this.metricsData)) &&
        (!this.chartData || !Array.isArray(this.chartData))
      )
        return null;

      // For metrics tab (time series data)
      if (this.activeTab === "metrics" && this.chartData?.length) {
        // Sum up values across all dates for the selected metric type
        return this.chartData.reduce((total, dayData) => {
          console.log("total:", total);
          switch (metricType) {
            case "maps":
              console.log("metricType:", metricType);
              console.log(
                "mobile_maps_impressions:",
                dayData.date,
                dayData.desktop_maps_impressions,
                dayData.mobile_maps_impressions,
                dayData.desktop_maps_impressions +
                  dayData.mobile_maps_impressions
              );
              return (
                total +
                (parseInt(dayData.desktop_maps_impressions) || 0) +
                (parseInt(dayData.mobile_maps_impressions) || 0)
              );
            case "search":
              return (
                total +
                (parseInt(dayData.desktop_search_impressions) || 0) +
                (parseInt(dayData.mobile_search_impressions) || 0)
              );
            case "directions":
              return total + (parseInt(dayData.direction_requests) || 0);
            case "calls":
              return total + (parseInt(dayData.call_clicks) || 0);
            case "website":
              return total + (parseInt(dayData.website_clicks) || 0);
            case "bookings":
              return total + (parseInt(dayData.bookings) || 0);
            case "food_orders":
              return total + (parseInt(dayData.food_orders) || 0);
            case "food_menu":
              return total + (parseInt(dayData.food_menu_clicks) || 0);
            default:
              return total;
          }
        }, 0);
      }

      // For performance tab (location-based data)
      if (this.activeTab === "performance" && Array.isArray(this.metricsData)) {
        return this.metricsData.reduce((total, metric) => {
          if (!metric) return total;

          switch (metricType) {
            case "maps":
              return (
                total +
                (parseInt(metric.desktop_maps_impressions) || 0) +
                (parseInt(metric.mobile_maps_impressions) || 0)
              );
            case "search":
              return (
                total +
                (parseInt(metric.desktop_search_impressions) || 0) +
                (parseInt(metric.mobile_search_impressions) || 0)
              );
            case "directions":
              return total + (parseInt(metric.direction_requests) || 0);
            case "calls":
              return total + (parseInt(metric.call_clicks) || 0);
            case "website":
              return total + (parseInt(metric.website_clicks) || 0);
            case "bookings":
              return total + (parseInt(metric.bookings) || 0);
            case "food_orders":
              return total + (parseInt(metric.food_orders) || 0);
            case "food_menu":
              return total + (parseInt(metric.food_menu_clicks) || 0);
            default:
              return total;
          }
        }, 0);
      }

      return 0;
    },
    getTooltipMessage(metric, type = "default") {
      const messages = {
        maps: {
          desktop: "Business impressions on Google Maps on Desktop devices",
          mobile: "Business impressions on Google Maps on Mobile devices",
          combined: "Total Maps impressions across all locations",
        },
        search: {
          desktop: "Business impressions on Google Search on Desktop devices",
          mobile: "Business impressions on Google Search on Mobile devices",
          combined: "Total Search impressions across all locations",
        },
        directions: {
          default: "Total direction requests across all locations",
          combined: "Total direction requests across all locations",
        },
        calls: {
          default:
            "The number of times the business profile call button was clicked",
          combined: "Total call clicks across all locations",
        },
        website: {
          default:
            "The number of times the business profile website was clicked",
          combined: "Total website clicks across all locations",
        },
        bookings: {
          default:
            "The number of bookings made from the business profile via Reserve with Google",
          combined: "Total bookings across all locations",
        },
        food_orders: {
          default:
            "The number of food orders received from the business profile",
          combined: "Total food orders across all locations",
        },
        food_menu: {
          default:
            "The number of clicks to view or interact with the menu content on the business profile",
          combined:
            "Total number of clicks to interact with the menu content across all locations",
        },
      };

      // Return appropriate message based on metric and type
      if (type === "desktop") {
        return messages[metric]?.desktop || messages[metric]?.default;
      } else if (type === "mobile") {
        return messages[metric]?.mobile || messages[metric]?.default;
      } else if (type === "combined") {
        return messages[metric]?.combined || messages[metric]?.default;
      } else {
        return messages[metric]?.default;
      }
    },
    handleClickOutside(event) {
      const profileDropdown = this.$refs.showStoreDropdown;
      if (profileDropdown && !profileDropdown.contains(event.target)) {
        this.closeProfileDropdown();
      }
    },
    closeProfileDropdown() {
      if (this.showStoreDropdown) {
        this.showStoreDropdown = false;
      }
    },
    toggleStoreDropdown(event) {
      this.showStoreDropdown = !this.showStoreDropdown;
      event.stopPropagation();
    },
    async fetchStorePosts(profile) {
      if (profile.checked) {
        this.selectedWIDs.push(profile.wid);
      } else {
        this.selectedWIDs = this.selectedWIDs.filter(
          (wid) => wid !== profile.wid
        );
      }
      this.selectedWID = profile.wid;
      this.showStoreDropdown = false;
      // Clear chart data if no locations selected
      if (this.selectedWIDs.length === 0) {
        this.chartData = [];
        if (this.metricsChart) {
          this.metricsChart.destroy();
          this.metricsChart = null;
        }
        return; // Don't fetch data if no locations selected
      }
      this.updatePaginatedWIDs();
      await this.fetchDataBasedOnTab();
    },
    async fetchMetricsForAllLocations() {
      try {
        this.isLoading = true;
        const days = this.selectedDateRange.match(/\d+/)[0];
        let wids = this.paginatedWIDs ? this.paginatedWIDs : this.selectedWIDs;
        if (!wids?.length) {
          this.metricsData = [];
          return;
        }
        // let cachedData = [];

        // Try to get data from cache first
        // Try to get data from cache first using months range
        const months = this.selectedDate.months;
        const cachedData = this.metricsCache.getLocationsData(months, wids);

        // If we have all locations in cache, use cached data
        if (cachedData.length === wids.length) {
          this.metricsData = this.deserializeMetricsData(cachedData);
          this.sortData("location");
          this.isLoading = false;
          return;
        }

        // Find which locations need to be fetched
        const cachedWids = new Set(cachedData.map((d) => d.location.wid));
        const widsToFetch = wids.filter((wid) => !cachedWids.has(wid));

        // if (!this.isCurrentMonth) {
        //   // Try to get data from cache first
        //   cachedData = this.metricsCache.getLocationsData(
        //     this.selectedDate.year,
        //     this.selectedDate.month,
        //     wids
        //   );
        //   console.log('cachedData:', cachedData)
        //   // If we have all locations in cache, use cached data
        //   if (cachedData.length === wids.length) {
        //     this.metricsData = this.deserializeMetricsData(cachedData);
        //     this.sortData("location");
        //     this.isLoading = false;
        //     return;
        //   }
        //   // Find which locations need to be fetched
        //   const cachedWids = new Set(cachedData.map(d => d.location.wid));
        //   // console.log('cachedWids:', cachedWids)
        //   wids = wids.filter(wid => !cachedWids.has(wid));
        //   // console.log('wids:', wids)
        // }

        // Fetch all connected apps data in parallel
        const connectedAppsPromises = widsToFetch?.map(async (wid) => {
          const connectedAppsRef = collection(
            db,
            "users",
            this.currentUID,
            "fly_workspaces",
            wid,
            "connected-apps"
          );
          const gbpDoc = await getDocs(
            query(
              connectedAppsRef,
              where("__name__", "==", "google_business_profile")
            )
          );
          return { wid, gbpDoc };
        });

        const connectedAppsResults = await Promise.all(connectedAppsPromises);
        // Process results and build payloads
        const locationTitlesMapping = {};
        const payloads = [];

        connectedAppsResults.forEach(({ wid, gbpDoc }) => {
          if (gbpDoc.empty) return;

          const gbpData = gbpDoc.docs[0].data();
          const accounts = gbpData.accounts;
          if (!accounts?.length) return;

          const accountId = accounts[0].id || "";

          // Use for...of instead of forEach for better performance with early returns
          for (const account of accounts) {
            const locations = account.locations;
            if (!locations?.length) continue;

            // Pre-calculate common values
            const basePayload = {
              uid: this.currentUID,
              wid,
              platform: "google_business_profile",
              account: accountId,
              days: parseInt(days),
              resource_type: "detailed",
              range: this.selectedDate.months,
            };

            // Use for...of for better performance
            for (const location of locations) {
              locationTitlesMapping[wid] = {
                title: location.title,
                locationId: location.name,
                wid,
              };
              payloads.push({
                ...basePayload,
                location: location.name,
              });
            }
          }
        });
        // console.log('payloads:', payloads)
        // Fetch all metrics in parallel
        const fetchPlatformMetrics = httpsCallable(
          functions,
          "fetchPlatformMetrics"
        );
        const results = await Promise.all(
          payloads.map((payload) => fetchPlatformMetrics(payload))
        );

        // Process results
        const newMetricsData = results
          .map((result, index) => {
            const metrics = result.data;
            if (!this.hasMetrics(metrics)) return null;
            const locationWid = payloads[index].wid;
            const store =
              this.stores.find((store) => store.wid === locationWid) || {};

            return {
              location: {
                wid: locationWid,
                name: store.name || "",
                sub_locality: store.sub_locality || "",
                city: store.city || "",
                state: store.state || "",
                storeLocation: store.storeLocation || "",
                title: locationTitlesMapping[locationWid]?.title || "",
              },
              ...this.extractMetrics(metrics),
            };
          })
          .filter(Boolean);
        // if (!this.isCurrentMonth) {
        //   this.metricsCache.setLocationsData(
        //     this.selectedDate.year,
        //     this.selectedDate.month,
        //     this.serializeMetricsData(newMetricsData)
        //   );
        // }

        // Combine with existing cached data if any
        // this.metricsData = !this.isCurrentMonth ?
        //   [...(cachedData || []), ...this.deserializeMetricsData(newMetricsData)] :
        //   newMetricsData;

        // Cache new data
        // Cache new data with months range
        this.metricsCache.setLocationsData(
          months,
          this.serializeMetricsData(newMetricsData)
        );

        // Combine with existing cached data
        this.metricsData = [
          ...this.deserializeMetricsData(cachedData),
          ...this.deserializeMetricsData(newMetricsData),
        ];

        // this.metricsData = newMetricsData;

        this.sortData("location");
      } catch (error) {
        console.error("Error fetching metrics:", error);
        this.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    // Serialize metrics data for storage
    serializeMetricsData(data) {
      return data.map((metric) => ({
        ...metric,
        location: {
          wid: metric.location.wid,
          name: metric.location.name,
          sub_locality: metric.location.sub_locality,
          city: metric.location.city,
          state: metric.location.state,
          storeLocation: metric.location.storeLocation,
          title: metric.location.title,
        },
        // Include only numeric metrics to save space
        desktop_maps_impressions: metric.desktop_maps_impressions,
        mobile_maps_impressions: metric.mobile_maps_impressions,
        desktop_search_impressions: metric.desktop_search_impressions,
        mobile_search_impressions: metric.mobile_search_impressions,
        direction_requests: metric.direction_requests,
        call_clicks: metric.call_clicks,
        website_clicks: metric.website_clicks,
        bookings: metric.bookings,
        food_orders: metric.food_orders,
        food_menu_clicks: metric.food_menu_clicks,
      }));
    },

    // Deserialize metrics data from storage
    deserializeMetricsData(data) {
      return data.map((metric) => ({
        ...metric,
      }));
    },
    async fetchStores() {
  const uid = this.currentUID;
  const storeRef = collection(db, "users", uid, "fly_workspaces");
  onSnapshot(storeRef, async (querySnapshot) => {
    this.stores = [];
    this.selectedWIDs = [];
    querySnapshot.forEach((doc) => {
      let storeData = doc.data();
      if (
        storeData.storeWorkspace &&
        storeData.admin === this.WID &&
        storeData.connected_apps?.includes("google_business_profile")
      ) {
        storeData.wid = doc.id;
        storeData.type = "store";
        storeData.display_name = `${storeData.sub_locality} - ${storeData.name}`;
        storeData.checked = true;
        this.stores.push(storeData);
        this.selectedWIDs.push(doc.id);
        
        // Initialize showPreviousData to true for this store
        this.$set(this.showPreviousData, doc.id, true);
      }
    });
    this.updatePaginatedWIDs();
    // Fetch metrics for all initially selected stores
    if (this.selectedWIDs.length > 0) {
      await this.fetchDataBasedOnTab();
      // Fetch previous metrics for all stores
      for (const wid of this.selectedWIDs) {
        await this.fetchPreviousMetrics(wid);
      }
    }
  });
},
    async selectDateRange() {
      if (!this.selectedDate) return;
      
      // Reset and reopen all dropdowns
      this.selectedWIDs.forEach(wid => {
        this.$set(this.showPreviousData, wid, true);
      });
      
      // Fetch new data
      await this.fetchDataBasedOnTab();
      
      // Fetch previous metrics for all stores with the new date range
      for (const wid of this.selectedWIDs) {
        await this.fetchPreviousMetrics(wid);
      }
    },
    hasMetrics(metrics) {
      return Object.keys(metrics || {}).some(
        (key) =>
          metrics[key] !== undefined &&
          [
            "desktop_maps_impressions",
            "mobile_maps_impressions",
            "desktop_search_impressions",
            "mobile_search_impressions",
            "direction_requests",
            "call_clicks",
            "website_clicks",
            "bookings",
            "food_orders",
            "food_menu_clicks",
          ].includes(key)
      );
    },
    extractMetrics(metrics) {
      console.log('Extracting metrics from:', metrics);
      return {
        desktop_maps_impressions: parseInt(metrics?.desktop_maps_impressions) || 0,
        mobile_maps_impressions: parseInt(metrics?.mobile_maps_impressions) || 0,
        desktop_search_impressions: parseInt(metrics?.desktop_search_impressions) || 0,
        mobile_search_impressions: parseInt(metrics?.mobile_search_impressions) || 0,
        direction_requests: parseInt(metrics?.direction_requests) || 0,
        call_clicks: parseInt(metrics?.call_clicks) || 0,
        website_clicks: parseInt(metrics?.website_clicks) || 0,
        bookings: parseInt(metrics?.bookings) || 0,
        food_orders: parseInt(metrics?.food_orders) || 0,
        food_menu_clicks: parseInt(metrics?.food_menu_clicks) || 0
      };
    },
    async togglePreviousData(wid) {
      console.log('togglePreviousData called for wid:', wid);
      this.$set(this.showPreviousData, wid, !this.showPreviousData[wid]);
      
      if (this.showPreviousData[wid] && !this.previousMetrics[wid]) {
        console.log('Fetching previous metrics for wid:', wid);
        await this.fetchPreviousMetrics(wid);
      } else {
        console.log('Previous metrics already loaded or dropdown closed');
      }
    },

    async fetchPreviousMetrics(wid) {
      try {
        console.log('Starting fetchPreviousMetrics for wid:', wid);
        
        // Get the selected months from the current date range
        const selectedMonths = this.selectedDate?.months;
        console.log('Selected months:', selectedMonths);

        if (!selectedMonths) {
          console.warn('No months selected');
          return;
        }

        // Calculate previous date range based on selected months
        const currentEndDate = new Date();
        const currentStartDate = new Date();
        currentStartDate.setMonth(currentStartDate.getMonth() - selectedMonths);
        
        const previousEndDate = new Date(currentStartDate);
        const previousStartDate = new Date(previousEndDate);
        previousStartDate.setMonth(previousStartDate.getMonth() - selectedMonths);

        console.log('Date ranges:', {
          selectedMonths,
          previousStartDate,
          previousEndDate,
          currentStartDate,
          currentEndDate
        });

        const connectedAppsRef = collection(
          db,
          "users",
          this.currentUID,
          "fly_workspaces",
          wid,
          "connected-apps"
        );
        
        const gbpDoc = await getDocs(
          query(connectedAppsRef, where("__name__", "==", "google_business_profile"))
        );

        if (!gbpDoc.empty) {
          const gbpData = gbpDoc.docs[0].data();
          const accounts = gbpData.accounts;
          
          if (accounts?.length) {
            const accountId = accounts[0].id;
            const location = accounts[0].locations?.[0];

            if (location) {
              const payload = {
                uid: this.currentUID,
                wid,
                platform: "google_business_profile",
                account: accountId,
                location: location.name,
                dateRange: {
                  start: previousStartDate.toISOString().split('T')[0],
                  end: previousEndDate.toISOString().split('T')[0]
                },
                resource_type: "detailed"
              };

              console.log('Calling fetchPlatformMetrics with payload:', payload);
              const fetchPlatformMetrics = httpsCallable(functions, "fetchPlatformMetrics");
              const result = await fetchPlatformMetrics(payload);
              console.log('Raw API response:', result);

              if (result.data) {
                let metricsData;
                if (result.data.data) {
                  metricsData = result.data.data;
                } else if (result.data.metrics) {
                  metricsData = result.data.metrics;
                } else {
                  metricsData = result.data;
                }

                const metrics = this.extractMetrics(metricsData);
                console.log('Extracted metrics:', metrics);

                if (Object.values(metrics).some(value => value > 0)) {
                  this.$set(this.previousMetrics, wid, metrics);
                } else {
                  this.$set(this.previousMetrics, wid, {
                    desktop_maps_impressions: 0,
                    mobile_maps_impressions: 0,
                    desktop_search_impressions: 0,
                    mobile_search_impressions: 0,
                    direction_requests: 0,
                    call_clicks: 0,
                    website_clicks: 0,
                    bookings: 0,
                    food_orders: 0,
                    food_menu_clicks: 0
                  });
                }
              }
            }
          }
        }
      } catch (error) {
        console.error("Error fetching previous metrics:", error);
        this.$set(this.previousMetrics, wid, {
          desktop_maps_impressions: 0,
          mobile_maps_impressions: 0,
          desktop_search_impressions: 0,
          mobile_search_impressions: 0,
          direction_requests: 0,
          call_clicks: 0,
          website_clicks: 0,
          bookings: 0,
          food_orders: 0,
          food_menu_clicks: 0
        });
      }
    },
    calculatePercentageChange(current, previous) {
      // If both values are 0, return 0%
      if (current === 0 && previous === 0) return 0;
      
      // If previous is 0 and current is not, return 100%
      if (previous === 0 && current > 0) return 100;
      
      // If current is 0 and previous is not, return -100%
      if (current === 0 && previous > 0) return -100;
      
      // Calculate percentage change
      const change = ((current - previous) / previous) * 100;
      
      // Cap the percentage between -100 and 100
      if (change > 100) return 100;
      if (change < -100) return -100;
      
      // Round to whole number
      return Math.round(change);
    },
    getPercentageIcon(current, previous) {
      // If both values are 0, show minus
      if (current === 0 && previous === 0) return 'mdi-minus';
      
      // If there's any change, show appropriate arrow
      if (current > previous) return 'mdi-arrow-up';
      if (current < previous) return 'mdi-arrow-down';
      return 'mdi-minus';
    },
    getPercentageClass(current, previous) {
      // If both values are 0, use gray
      if (current === 0 && previous === 0) return 'text-gray-500';
      
      // If there's any change, use appropriate color
      if (current > previous) return 'text-green-500';
      if (current < previous) return 'text-red-500';
      return 'text-gray-500';
    },
  },

  async mounted() {
    this.fetchGMBLogo();
    await this.fetchStores();
    this.fetchChartMetrics();
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
    if (this.metricsChart) {
      this.metricsChart.destroy();
      this.metricsChart = null;
    }
  },
};
</script>

<style scoped>
.text-xs {
  font-size: 0.75rem;
}
:deep(.v-text-field.location-input) {
  .v-input__slot {
    min-height: 40px !important;
  }

  .v-text-field__placeholder {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
  }

  input {
    font-size: 14px;
  }

  fieldset {
    border-color: rgba(0, 0, 0, 0.6) !important;
  }
}
.text-center {
  text-align: center;
}
img {
  object-fit: cover;
}
.cursor-pointer {
  cursor: pointer;
}
.hover\:bg-gray-100:hover {
  background-color: #f7fafc;
}
.leading-tight {
  line-height: 1.25;
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 10;
}
.skeleton-loader {
  display: inline-block;
  width: 50px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: skeleton-loading 1.5s infinite;
}
.overflow-x-auto {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
}
/* Optional: Custom scrollbar styling */
.overflow-x-auto::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.overflow-x-auto::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.overflow-x-auto::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.overflow-x-auto::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tooltip-container {
  position: relative;
  z-index: 40;
}
.v-tooltip__content {
  z-index: 60 !important;
  position: fixed !important;
}
.v-icon {
  z-index: 40;
  position: relative;
}

.showStoreDropdown {
  background-color: white; /* Ensure solid background */
  z-index: 50;
  position: relative;
}

.date-select {
  position: relative;
  z-index: 40;
}

button.tabs {
  border-width: 2px;
  border-style: solid;
  border-color: #8056de;
}
button.tabs.active {
  background-color: #8056de;
  color: white;
}
.metrics-select {
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* This ensures the v-select dropdown menu appears above everything */
:deep(.v-menu__content) {
  z-index: 60 !important;
}
</style>
